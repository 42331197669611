<template>
    <div class="flow-item">
        <img :src="flow" />
    </div>
</template>
<script>
    import flow from "@/assets/flow.png" 
    export default {
        data () {
            return {
                flow
            }
        }
    }
</script>
<style lang="less">
    .flow{
        background: #eff0f5;
        &-item{
            width: 12.8rem;
            margin: 0 auto;
            img{
                width: 100%;
            }
        }
        
    }
</style>
