<template>
    <div>
        <img :src="winwin" />
        <Row class="winwin-item block">
            <p>合作共赢 </p>
        </Row>
    </div>
</template>
<script>
    import winwin from "@/assets/winwin.png" 
    export default {
        data () {
            return {
                winwin
            }
        }
    }
</script>
<style lang="less">
    .winwin{
        >div{
            font-size: 0;
            position: relative;
        }
        img{
            width: 100%;
        }
        &-item{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            p{
                width: 12.8rem;
                margin: 0 auto;
                font-size: 0.78rem;
                color: #fff;
                position: relative;
                display: block;
                top: 2.6rem
            }
        }
    }
</style>
