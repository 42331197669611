<template>
        <Menu mode="horizontal" :theme="theme" active-name="1" @on-select="anchorJump">
            <Submenu name="1">
                <template slot="title">
                    海外仓项目
                </template>
                <MenuItem name="service-split">订单中转分发</MenuItem>
                <MenuItem name="service-exchange">退货换货处理</MenuItem>
                <MenuItem name="service-split">配件仓储补发</MenuItem>
                <MenuItem name="service-bill">清关关税处理</MenuItem>
                <MenuItem name="service-enservice">英文客服服务</MenuItem>
                <MenuItem name="service-helpsend">订单一件代发</MenuItem>
                <MenuItem name="service-labelling">FBA贴标换标</MenuItem>
            </Submenu>
            <Submenu name="2">
                <template slot="title">
                    电商平台解决方案
                </template>
                <MenuItem name="solution-0">对接店小秘马帮</MenuItem>
                <MenuItem name="solution-1">独立站API对接</MenuItem>
                <MenuItem name="solution-2">USPS折扣面单</MenuItem>
                <MenuItem name="solution-3">美多仓联动发货</MenuItem>
            </Submenu>
            <MenuItem name="3">
                关于我们
            </MenuItem>
            <MenuItem name="4">
                联系我们
            </MenuItem>
            <!--<MenuItem name="4">
                <Button @click="openSys" type="error" shape="circle">登录<span v-if="showAll">海外仓系统</span></Button>
            </MenuItem>-->
            <Modal v-model="showAbout" footer-hide width="80%" title="关于我们">
                <About/>
            </Modal>
            <Modal v-model="showAbout1" footer-hide width="80%" title="联系我们">
                <About1/>
            </Modal>
        </Menu>
</template>

<script>
    import About from "../../about"
    import About1 from "../../about/index1"
    export default {
        components: {
            About, About1
        },
        data () {
            return {
                theme: 'primary',
                showAll:true,
                showAbout: false,
                showAbout1: false
            }
        },
        mounted(){
            let htmlWidth=document.documentElement.cilentWidth||document.body.clientWidth;
            if(htmlWidth<600){
                this.showAll = false
            }
        },
        methods:{
            openSys(){
                window.open('https://erp.kjvendor.com','_blank')
            },
            anchorJump(e){
                console.log(e)
                if(e==='3'){
                    this.showAbout = true
                }else if(e==='4'){
                    this.showAbout1 = true
                    console.log('denglu')
                }else{
                    let tagList = e.split('-')
                    let docId = "#"+tagList[0]+"-anchor"
                    const anchorEle = document.querySelector(docId);
                    if (anchorEle) {
                        anchorEle .scrollIntoView(true);
                        this.$emit('swithItem',tagList[0],tagList[1])
                    }
                }
                
            }
            
        }
    }
</script>
<style lang="less">
    .banner-menu{
        background: none;
    }
</style>
