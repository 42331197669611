import axios from "@/libs/api.request"
/**
 * 示例接口
 */
export const freeMoney = (data) => {
  return axios.request({
    url: '/api/get_shipping_fee',
    data: data,
    method: 'post'
  })
}
