<template>
    <div class="about">
        <div class="intro">
            江苏加诺美仓供应链管理有限公司，我司主要从事跨境中小件品类海外仓服务。加诺海外仓整合物流渠道资源，优化服务环节，为中小件跨境电商卖家提供订单包裹转发、退货接收处理、二次包装、异常订单处理、关税账单处理、退货库存运输回国等优质海外仓服务，并不断推出一些列增值服务，为中小件品类跨境电商卖家提供全面、优质的海外仓服务。
        </div>
        <div>
            <div class="about-title">
                <b>联系方式:</b>
            </div>
            <div class="about-content">
                公司汇聚行业精英，拥有高端、诚信、专业、高效的服务团队和技术团队，为广大客户提供安全、稳定的跨境电商服务。如需商业合作，请联系我们！
            </div>
            <div class="about-title">
                <b>工作时间:</b>
            </div>
            <div class="about-content">
               <b>国内销售、客服团队：</b>周一到周五（北京时间9:00 - 18:00）
               <br>
               <b>美国仓库、客服团队：</b>周一到周五（美中9:00am-5:00pm）
            </div>
            <br>
            <div class="contact">
                <b>联系人：</b>Jack Liu  <b>微信：</b>jingliu8858 <b>电话：</b>18914773784 <br>
                <b>公司邮箱：</b>klleo2019@gmail.com <br>
                <b>国内公司地址：</b>江苏省镇江市丹阳华都SOHO 23栋1203室 <br>
                <b>美国公司仓库地址：</b>美东MI、美中TX、美西CA <br>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data () {
            return {
                
            }
        }
    }
</script>
<style lang="less">
    .about{
        position: relative;
        width: 100%;
        padding: 0 0.4rem;
        margin: 0.4rem auto;
        .intro{
            font-weight: bolder;
            font-size: 16px;
            line-height: 32px;
            text-indent: 32px;
            color: #333;
            margin-bottom: 20px;
        }  
        .about-title{
            font-size: 16px;
            line-height: 32px;   
        }
        .about-content{
            margin: 10px 0 20px 0;
            font-size: 16px;
            line-height: 28px;
        }
        .contact{
            font-size: 16px;
            line-height: 32px;
        }
    }
</style>
