<template>
    <div class="home-carousel" :style="{'height':carouselHeight+'px'}">
        <Carousel
        v-model="value3"
        :autoplay="setting.autoplay"
        :autoplay-speed="setting.autoplaySpeed"
        :dots="setting.dots"
        :radius-dot="setting.radiusDot"
        :trigger="setting.trigger"
        :arrow="setting.arrow">
        <CarouselItem v-for="(item,key) in carouselList" :key="key" >
            <div class="demo-carousel">
                <img class="cas-img" :src="item">
            </div>
        </CarouselItem>
    </Carousel>
    </div>
</template>
<script>
    import casBg from "@/assets/banner.png" 
    export default {
        name:'Homecarousel',
        data () {
            return {
                carouselHeight:'',
                carouselList:[casBg],
                value3: 0,
                setting: {
                    autoplay: false,
                    autoplaySpeed: 2000,
                    dots: 'inside',
                    radiusDot: true,
                    trigger: 'click',
                    arrow: 'never'
                }
            }
        },
        mounted(){
            this.calcCarouselHeight()
        },
        methods:{
            calcCarouselHeight(){
                // 1920*900 c 
                let htmlWidth=document.documentElement.cilentWidth||document.body.clientWidth;
                if(htmlWidth<800){
                    this.carouselHeight=''
                }else{
                    this.carouselHeight = document.body.clientWidth * 900  / 1920
                    window.onresize = () => {
                        this.carouselHeight = document.body.clientWidth * 900  / 1920
                    }
                }
               
            }
            
        }
    }
</script>
<style lang="less">
    .home-carousel{
        .cas-img{
            width: 100%;
        }
        .ivu-carousel-dots-inside{
            bottom: 0.4rem;
        }
        .ivu-carousel-dots{
            height: unset;
            li{
                margin: 0 0.1rem;
                button.radius{
                    width: 0.16rem!important;
                    height: 0.16rem!important;
                }
            }
        }
    }
   
</style>
