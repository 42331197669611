<template>
    <div>
        <Row class="advantage-content block">
            <div class="title">
                <h1>加诺海外仓服务优势</h1>
                 <div class="dash"></div>
            </div>
            <Row class="content">
                <Col span="10" class="content-img">
                    <img v-if="activeTab=='safe'" :src="safeleft"/>
                    <img v-if="activeTab=='stable'" :src="stableleft"/>
                    <img v-if="activeTab=='high'" :src="highleft"/>
                </Col>
                <Col span="12" class="content-text">
                    <img class="advantagebg" :src="advantagebg"/>
                    <div class="content-text-item">
                        <Tabs type="card" :value="activeTab" @on-click="clickTab">
                            <TabPane label="安全" name="safe">
                                <div class="advantage-tab-img">
                                    <img :src="safe" />
                                    <p class="advantage-tab-">-</p>
                                </div>
                                <div class="advantage-tab-text">
                                    签订商业合作合同、开具正规中美发票
                                </div>
                            </TabPane>
                            <TabPane label="稳定" name="stable">
                                <div class="advantage-tab-img">
                                    <img :src="stable" />
                                    <p class="advantage-tab-">-</p>
                                </div>
                                <div class="advantage-tab-text">
                                    深根北美市场服务、中美公司合规运营、美东美西同步发货 
                                </div>
                            </TabPane>
                            <TabPane label="高效" name="high">
                                <div class="advantage-tab-img">
                                    <img :src="high" />
                                    <p class="advantage-tab-">-</p>
                                </div>
                                <div class="advantage-tab-text">
                                    中美华人服务团队、自研仓库管理系统、系统管控作业流程
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </Col>
            </Row>
        </Row>
    </div>
</template>
<script>
    import safeleft from "@/assets/safeleft.png" 
    import stableleft from "@/assets/stableleft.png" 
    import highleft from "@/assets/highleft.png" 
    import advantagebg from "@/assets/advantagebg.png" 
    import high from "@/assets/high-w.png" 
    import stable from "@/assets/stable-w.png" 
    import safe from "@/assets/safe-w.png" 
    export default {
        data () {
            return {
                theme: 'primary',
                safeleft,
                stableleft,
                highleft,
                advantagebg,
                safe,
                stable,
                high,
                activeTab:'safe'
            }
        },
        methods:{
            clickTab(item){
                this.activeTab = item
            }
        }
    }
</script>
<style lang="less">
    .advantage{
       
        .content{
            width: 100%;
            &-img{
                flex: 0 0 42.0833333333%;
                max-width: 42.0833333333%;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .content-text-item{
                .ivu-tabs-content{
                    margin-left: 10px
                }
            }
            &-text{
                flex: 0 0 57.9166666667%;
                max-width: 57.91666667%;
                .advantagebg{
                    width: 100%;
                    height: 100%;
                }
                &-item{
                    position: absolute;
                    width: 80%;
                    top: 50%;
                    left: 0.48rem;
                    transform: translate(0,-50%);
                    .advantage-tab-img{
                        margin-bottom: 10px;
                        img{
                            height: 0.56rem;
                        }
                        .advantage-tab-{
                            width: 0.56rem;
                            height: 0.06rem;
                            background: #b80103;
                            margin: 0.3rem 0 0.24rem 0;
                        }
                    }
                    .advantage-tab-text{
                        font-size: 24px;
                        color: #fff;
                        font-weight: bolder;
                    }
                    .ivu-tabs-bar{
                        border-bottom:none;
                        margin-bottom: 0.8rem;
                        .ivu-tabs-ink-bar{
                            display: none;
                        }
                        .ivu-tabs-nav-container{
                            height: unset;
                        }
                        .ivu-tabs-tab{
                            font-size: 0.28rem;
                            line-height: 1;
                            color: #fff;
                            padding: 0.2rem 0.48rem;
                            background: rgba(181,1,13,0.4);
                            border-radius: 1rem;
                            border:none;
                            margin-right: 0.5rem;
                            height: unset;
                        }
                        .ivu-tabs-tab-active{
                            height: unset;
                            font-size: 0.28rem;
                            background: #b5010d;
                        }
                        
                    }
                    h2{
                        font-weight:normal;
                        margin-bottom: 0.2rem;
                        font-size: 0.3rem;
                        color:#b80103
                    }
                    p{
                        font-size: 0.24rem;
                        line-height: 1.8;
                        color:#666
                    }
                }
            }
        }
    }
</style>
