<template>
    <div>
        <div class="footer-item">
       <Row class="footer-item-content">
           <Col span="4" class="footer-item-content-logo">
                <img :src="logo" />
           </Col>
           <Col span="16">
                <div class="contact">
                    <div>
                         <b>联系人：</b>Jack Liu<br>
                        <b>手机:</b>18914773784 (微信同号)
                    </div>
                    <div>
                        <b>邮箱:</b>klleo2019@163.com <br>
                        <b>公司地址：</b>江苏省丹阳市华都SOHO 23幢-1203室
                    </div>
                    
                </div>
           </Col>
           <Col span="4">
           <div class="footer-item-content-wx">
                <div class="wx-img" >
                   <img :src="qr"/>
                </div>
           </div>
           </Col>
        </Row>
        </div>
        <!--<Row class="footer-copyright">
           <p>© Copyright  江苏加诺美仓供应链管理有限公司 版权所有 苏ICP备19023320-1  <span class="split">| </span>   增值电信业务许可证编号：苏B2-20190486</p>
        </Row>-->
        <Row class="footer-copyright">
           <p>© Copyright  江苏加诺美仓供应链管理有限公司 版权所有 <a href='http://beian.miit.gov.cn/'>苏ICP备2021026064号-1</a></p>
        </Row>
    </div>
</template>
<script>
    import logo from "@/assets/logo.png" 
    import wx from "@/assets/wx.png" 
    import wxactive from "@/assets/wx-active.png" 
    import qr from "@/assets/qr.png" 
    export default {
        data () {
            return {
                logo,
                wx,
                wxactive,
                qr
            }
        }
    }
</script>
<style lang="less">
    .footer{
        &-item{
            background: #313131;
            &-content{
                width: 12.8rem;
                padding: 0.6rem 0;
                margin: 0 auto;
                color: #fff;
                text-align: center;
                &-logo{
                    margin-top: 0.2rem;
                }
                &-text{
                    line-height: 2;
                    font-size: 0.18rem;
                }
                &-wx{
                    display: flex;
                    justify-content: flex-end;
                    .wx-img{
                        width: 0.64rem;
                        margin-left: 0.1rem;
                        margin-top:0.06rem;
                        img{
                            width:100%;
                        }
                    }
                }
                .contact{
                    text-align:left;
                    display: flex;
                    padding-left: 0.24rem;
                    margin-top: 0.1rem;
                    div{
                        &:first-child{
                            margin-right:0.2rem ;
                        }
                    }
                }
            }
            img{
                width: 100%;
            }
        }
        &-copyright{
            background: #202020;
            p{
                width: 100%;
                font-size: 0.14rem;
                line-height: 3;
                text-align: center;
                color: #eff0f5;
                .split{
                    padding: 0 0.1rem;
                }
            }
        }
        
    }
</style>
