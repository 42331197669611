<template>
    <div id="solution-anchor">
        <Row class="solution-item block">
            <div class="title">
                <h1>电商平台解决方案</h1>
                <div class="dash"></div>
            </div>
            <Row class="content">
                <Col span="6" v-for="(item,index) in solutionList"  :key="index" class="content-item" >
                    <div class="item-base"  @mouseenter="enter(item)">
                        <img class="item-base-img" :src="item.base.img"/>
                        <div class="item-base-text">{{item.base.text}}</div>
                    </div>
                    <div class="item-mask" :style="{'display':item.active?'block':'none'}">
                        <div class="item-mask-contaniner">
                            <img :src="item.mask.img">
                            <div>{{item.mask.text}}</div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Row>
    </div>
</template>
<script>
    import store from "@/assets/store.png" 
    import storeIcon from "@/assets/store-icon.png" 
    import api from "@/assets/api.png" 
    import apiIcon from "@/assets/api-icon.png" 
    import print from "@/assets/print.png" 
    import printIcon from "@/assets/print-icon.png" 
    import send from "@/assets/send.png" 
    import sendIcon from "@/assets/send-icon.png" 
    export default {
        data () {
            return {
                solutionList:[
                    {
                        active:false,
                        base:{
                            img:store,
                            text:'电商平台'
                        },
                        mask:{
                            img:storeIcon,
                            text:'对接店小秘、马帮ERP 订单处理更快速高效'
                        }
                    },
                    {
                        active:false,
                        base:{
                            img:api,
                            text:'API对接'
                        },
                        mask:{
                            img:apiIcon,
                            text:'支持独立站独立API对接,数据实时传输、安全稳定'
                        }
                    },
                    {
                        active:false,
                        base:{
                            img:print,
                            text:'USPS 折扣面单'
                        },
                        mask:{
                            img:printIcon,
                            text:'USPS 折扣面单'
                        }
                    },
                    {
                        active:false,
                        base:{
                            img:send,
                            text:'美多仓联动发货'
                        },
                        mask:{
                            img:sendIcon,
                            text:'美东、美中、美西同步发货，更快订单交付时效'
                        }
                    }
                ],
            }
        },
        props: {
            activeSolution: String
        },
        watch:{
            activeSolution(val){
                console.log(this.solutionList)
                this.disAbleAll()
                this.solutionList[Number(val)].active = true
            }
        },
        methods:{
            disAbleAll(){
                this.solutionList.forEach(item=>{
                    item.active = false
                })
            },
            enter(item){
                  this.disAbleAll()
                item.active = true

            }
        }
    }
</script>
<style lang="less">
    .solution{
        width: 12.8rem;
        margin: 0 auto;
        &-item{
            
            .content{
                width: 100%;
                margin-bottom: 0.4rem;
                &-item{
                    padding: 0.05rem;
                    .item-base{
                        font-size: 0;
                        &-img{
                            width: 100%;
                        }
                        &-text{
                            width: 100%;
                            text-align: center;
                            font-size: 24px;
                            font-weight:bolder;
                            background: #eff0f5;
                            line-height: 1.3rem;
                        }
                    }
                    .item-mask{
                        display: none;
                        padding: 0.05rem;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        color: #fff;
                        text-align: center;
                        &-contaniner{
                            padding: 0.3rem;
                            height: 100%;
                            background: rgba(15, 30 ,102,0.7);
                            img{
                                margin: 0.7rem 0;
                            }
                            div{
                                font-weight: bolder;
                                line-height: 2;
                                font-size: 0.2rem;
                            }
                        }
                    }
                    &.active{
                        .item-mask{
                            display: block;
                        }
                    }
                }
            }

        }
    }
</style>
