<template>
    <div>
        <Row class="because-item block">
            <div class="title">
                <h1>因为专注  所以专业</h1>
                <div class="dash"></div>
            </div>
            <Row class="content">
                <Col span="12" class="content-item">
                    <div class="icon-circle">
                        <img :src="down" />
                    </div>
                    <div class="text">低于同行业服务价格</div>
                </Col>
                <Col span="10" offset="2" class="content-item">
                    <div class="icon-circle">
                        <img :src="up" />
                    </div>
                    <div class="text">高于同行业服务质量</div>
                </Col>
            </Row>
             <Row class="content">
                <Col span="12" class="content-item">
                    <div class="icon-circle">
                        <img :src="safe" />
                    </div>
                    <div class="text">更安全稳定分发渠道</div>
                </Col>
                <Col span="10" offset="2" class="content-item">
                    <div class="icon-circle">
                        <img :src="efficial" />
                    </div>
                    <div class="text">更高效服务响应时间</div>
                </Col>
            </Row>
        </Row>
    </div>
</template>
<script>
    import down from "@/assets/down-icon.png" 
    import up from "@/assets/up-icon.png" 
    import efficial from "@/assets/efficial-icon.png" 
    import safe from "@/assets/safe-icon.png" 
    export default {
        data () {
            return {
                down,
                up,
                efficial,
                safe
            }
        }
    }
</script>
<style lang="less">
    .because{
        width: 12.8rem;
        margin: 0 auto;
        &-item{
            
            .content{
                width: 100%;
                margin-bottom: 0.4rem;
                &-item{
                    display: flex;
                    .icon-circle{
                        width: 1rem;
                        height: 1rem;
                        border-radius: 1rem;
                        position: relative;
                        background: rgba(181, 1 ,13,0.2);
                        img{
                            width: 0.44rem;
                            left: 50%;
                            top: 50%;
                            position: absolute;
                            transform: translate(-50%,-50%);

                        }
                        
                    }
                    div{
                        text-indent: 0.24rem;
                        line-height: 1rem;
                        font-size: 0.3rem;
                        color: #b5010d;
                    }
                }
            }

        }
    }
</style>
