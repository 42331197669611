export default {
  /**
   * @description token在Cookie中存储的天数，默认1天
   */
  cookieExpires: 1,
  /**
   * @description
   * 是否使用国际化，默认为false
   *             如果不使用，则需要在路由中给需要在菜单中展示的路由设置meta: {title: 'xxx'}
   *              用来在菜单中显示文字
   */
  useI18n: false,
  /**
   * @description
   *  headers 请求头部新增参数
   *  grayVersion： 灰度标识
   *  W_operation_709 //   W代表web,operation代表各个平台标识，灰度文件中有，709代表项目版本号
   */
  headers: {
    'token': '87413ea2-95bc-4410-96d3-9a3c8a9693e4',
    'Content-Type': 'application/json'
  },
  /**
   * @description api请求基础路径
   */
  baseUrl: {
    dev: "https://erp.klleo.com/", // local 环境
    pro: "https://erp.klleo.com/"
  },
  /**
   * @description 默认打开的首页的路由name值，默认为home
   */
  homeName: "home",
  /**
   * @description 需要加载的插件
   */
  plugin: {
    "error-store": {
      showInHeader: false, // 设为false后不会在顶部显示错误日志徽标
      developmentOff: true // 设为true后在开发环境不会收集错误信息，方便开发中排查错误
    }
  }
};
