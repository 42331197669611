import axios from "axios"
// let responseLock = true;
import config from "@/config"
const headersObject = config.headers;
class HttpRequest {
  constructor(baseUrl) {
    this.baseUrl = baseUrl
    this.queue = {}
  }
  getInsideConfig() {
    const config = {
      baseURL: this.baseUrl,
      headers: {
        ...headersObject
      },
      withCredentials: false
    }
    return config
  }
  interceptors(instance) {
    // 请求拦截
    instance.interceptors.request.use(config => {
      return config
    }, error => {
      return Promise.reject(error)
    })
    // 响应拦截
    instance.interceptors.response.use(res => {
      console.log('res',res)
      if (res.data.result.code==='10000') {
        return res
      } else {
        let errorRes = ''
        return Promise.reject(errorRes)
      }
    }, error => {
      return Promise.reject(error)
    })
  }
  request(options) {
    const instance = axios.create({})
    // eslint-disable-next-line no-param-reassign
    options = Object.assign(this.getInsideConfig(), options)
    this.interceptors(instance, options.url)
    return instance(options)
  }
}
export default HttpRequest
