<template>
    <div>
        <Row class="why-choose-item block">
            <div class="title">
                <h1>为什么选择海外仓中转分发？</h1>
                 <div class="dash"></div>
            </div>
            <Row class="content">
                <Col span="12"  class="content-img">
                    <img :src="whychoose1"/>
                </Col>
                <Col span="12" class="content-text">
                    <div class="content-text-item">
                        <h2>更安全的通关渠道</h2>
                        <p>整箱清关+海外仓换单，清关速度更快、更安全，避免海关查扣造成产品+运输成本损失</p>
                    </div>
                </Col>
            </Row>
            <Row class="content">
                <Col span="12" class="content-text">
                    <div class="content-text-item">
                        <h2>更快的交付时效</h2>
                        <p>头程快递运输3天+尾程USPS运输平均 2-5天 订单交付时效更快，提升买家购物体验</p>
                    </div>
                </Col>
                <Col span="12"  class="content-img">
                    <img :src="whychoose2"/>
                </Col>
            </Row>
            <Row class="content">
                <Col span="12"  class="content-img">
                    <img :src="whychoose3"/>
                </Col>
                <Col span="12" class="content-text">
                    <div class="content-text-item">
                        <h2>更少的丢包率</h2>
                        <p>不管是旺季和淡定，【头程快递+尾程海外仓】中转模式更稳定可靠，丢包率降低至0.01%</p>
                    </div>
                </Col>
            </Row>
            <Row class="content" style="margin-bottom:0">
                <Col span="12" class="content-text">
                    <div class="content-text-item">
                        <h2>更靠谱的海外仓服务</h2>
                        <ul>
                            <li><p>本土发货地址、退货地址，买家更信赖</p></li>
                            <li><p>支持海外仓贴USPS面单</p></li>
                            <li><p>退换货服务</p></li>
                            <li><p>二次包装</p></li>
                            <li><p>配件补发</p></li>
                            <li><p>关税账单处理</p></li>
                        </ul>
                    </div>
                </Col>
                <Col span="12"  class="content-img">
                    <img :src="whychoose4"/>
                </Col>
            </Row>
        </Row>
    </div>
</template>
<script>
    import whychoose1 from "@/assets/whychoose1.png" 
    import whychoose2 from "@/assets/whychoose2.png" 
    import whychoose3 from "@/assets/whychoose3.png" 
    import whychoose4 from "@/assets/whychoose4.png" 
    export default {
        data () {
            return {
                theme: 'primary',
                whychoose1,
                whychoose2,
                whychoose3,
                whychoose4,
            }
        }
    }
</script>
<style lang="less">
    .why-choose{
        width: 12.8rem;
        margin: 0 auto;
        &-item{
            .content{
                width: 100%;
                margin-bottom: 1.8rem;
                &-img{
                    img{
                        width: 100%;
                    }
                }
                &-text{
                    &-item{
                        position: absolute;
                        width: 80%;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        h2{
                            font-weight:normal;
                            margin-bottom: 0.2rem;
                            font-size: 0.3rem;
                            color:#b80103
                        }
                        p{
                            font-size: 0.24rem;
                            line-height: 1.8;
                            color:#666
                        }
                    }
                }
            }

        }


    }
</style>
