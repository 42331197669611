<template>
    <div class="about">
        <div>
            <b>联系人：</b>Jack Liu  <b>微信：</b>jingliu8858 <b>电话：</b>18914773784 <br>
            <b>公司邮箱：</b>klleo2019@gmail.com <br>
            <b>国内公司地址：</b>江苏省镇江市丹阳华都SOHO 23栋1203室 <br>
            <b>美国公司仓库地址：</b>美东MI、美中TX、美西CA <br>
        </div>
    </div>
</template>
<script>
    export default {
        data () {
            return {
                
            }
        }
    }
</script>
<style lang="less">
    .about{
        position: relative;
        width: 100%;
        padding: 0 0.4rem;
        margin: 0.4rem auto;
        .intro{
            font-weight: bolder;
            font-size: 16px;
            line-height: 32px;
            text-indent: 32px;
            color: #333;
            margin-bottom: 20px;
        }  
        .about-title{
            font-size: 16px;
            line-height: 32px;   
        }
        .about-content{
            margin: 10px 0 20px 0;
            font-size: 16px;
            line-height: 28px;
        }
        .contact{
            font-size: 16px;
            line-height: 32px;
        }
    }
</style>
