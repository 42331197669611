<template>
<div class="home-form"> 
    <div class="home-form-container">
        <Form ref="homeForm" :model="homeForm" inline>
            <FormItem :class="`form-prepend`" prop="from" label="From" label-position="left">
                <Input v-model="homeForm.from" placeholder="发件邮编" >
                    <Select v-model="homeForm.from" slot="prepend" ref="warehouseCode" @on-select="changeSelectFrom">
                        <Option :key="index" v-for="(item,index) in fromSelectList" :value="item.value" :tag="item.data">{{item.label}}</Option>
                    </Select>
                </Input>
            </FormItem>
            <FormItem :class="`form-prepend`" prop="to" label="To" label-position="left">
                <Input v-model="homeForm.to" placeholder="收件邮编" ref="postalCode" >
                    <Select v-model="selectTo" slot="prepend" ref="country" @on-select="changeSelectTo">
                        <Option value="US">美国</Option>
                        <Option value="CAN">加拿大</Option>
                    </Select>
                </Input>
            </FormItem>
            <FormItem :class="`form-append- form-prepend form-prepend-text`" prop="weight" label="重量">
                <Input v-model="homeForm.weight" ref="packageWeight">
                    <div slot="prepend"> </div>
                    <Select v-model="selectWeight" slot="append" >
                        <!--<Option value="kg">kg</Option>-->
                        <Option value="Oz">Oz</Option>
                    </Select>
                </Input>
            </FormItem>
            <Button class="home-submit" type="error" @click="handleSubmit('homeForm')">测算运费</Button>
        </Form>
    </div>
    <Modal :class-name="`calc-pop`" footer-hide v-model="showSubmitRes">
        <div slot="close">
            <img :src="popClose">
        </div>
        <div class="pop-container">
            <div class="left-pic">
                <img :src="popPic"/>
            </div>
            <div class="main">
                <div class="main-title">运费测算</div>
                <div><p class="calc-res"><span>$</span>{{getedData&&getedData[0]&&getedData[0].basePrice}}</p></div>
                <div class="conclusion"> 
                    <ul>
                        <li>
                            <div class="title">From:</div>
                            <div class="content">{{selectFrom}}</div>
                        </li>
                        <li>
                            <div class="title">To:</div>
                            <div class="content">{{selectToLabel}}</div>
                        </li>
                        <li>
                            <div class="title">weight:</div>
                            <div class="content">{{homeForm.weight}}</div>
                        </li>
                    </ul>
                </div>
                <div slot="footer">
                    <Button class="pop-sure" @click="cancelPop" type="error">确定</Button>
                </div>
            </div>
        </div>
        <!-- <div :key="index" v-for="(item,index) in getedData">
            <p>${{item.basePrice}}</p>
        </div> -->
        
    </Modal>
</div>
</template>
<script>
    import popPic from "@/assets/popPic.png" 
    import popClose from "@/assets/close.png" 
    // 引入对应的接口，多个用,隔开
    import {freeMoney} from "@/api/calculate" 
    export default {
        data () {
            return {
                popPic,
                popClose,
                fromSelectList:[{
                    value:'48104',
                    data:'MI',
                    label:'美东MI',
                },
                {
                    value:'75074',
                    data:'TX',
                    label:'美中TX',
                },
                {
                    value:'94546',
                    data:'CA',
                    label:'美西CA',
                }],
                showSubmitRes:false,
                selectFrom:"美东MI",
                selectTo:"US",
                selectToLabel:"美国",
                selectWeight:"Oz",
                homeForm: {
                    from: '48104',
                    to: '',
                    weight: ''
                },
                ruleInline: {
                    from: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ],
                    to: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ],
                    weight: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ]
                },
                // 用到的返回值 先在这边定义，dom里面{{getedData}}直接取值  method里面 this.getedData取值
                getedData:[]
            }
        },
        methods: {
            cancelPop(){
                this.showSubmitRes = false
            },
            changeSelectFrom(selection){
                this.selectFrom = selection.label
                this.data = {
                  "warehouseCode": selection.tag
                }

                console.log(this.data.warehouseCode);
            },
            changeSelectTo(selection){
                this.selectToLabel = selection.label
            },
            handleSubmit(name) {
                this.$refs[name].validate((valid) => {
                    if (valid) {
                        // 
                        this.showSubmitRes = true
                        // 调接口
                        this.getData()
                    } else {
                        this.$Message.error('Fail!');
                    }
                })
            },
            getData(){

                if(!this.data) this.data = { "warehouseCode": 'MI' }

                // 开始调接口
                // params为入参
                let params = {
                    "warehouseCode":this.data.warehouseCode,
                    "country":this.$refs.country.value,
                    "postalCode":this.$refs.postalCode.value,
                    "packageWeight":this.$refs.packageWeight.value
                }

                console.log(params);

                freeMoney(params).then(res=>{
                    let resData = res.data
                    this.getedData = resData.data
                    console.log(res);
                })
            }
        }
    }
</script>
<style lang="less">
.home-form{
    background: rgba(255,255,255,0.4);
    padding: 0.2rem;
    border-radius: 0.8rem;
    .home-form-container{
        background: #fff;
        border-radius: 0.8rem;
        padding: 0.3rem 0.48rem;
        .ivu-form{
            color: #fff;
            .ivu-form-item-label{
                width: 1rem;
                color: #fff;
                font-size: 0.16rem;
                color: #b80103;
                line-height: 0.6rem;
                padding: 0 0.48rem 0 0.1rem;
            }
            .ivu-form-item{
                margin-bottom: 0;
            }
            .ivu-form-item-content{
                width: 1.8rem;
                margin-left: 1rem;
            }
            .form-prepend{
                &-text{
                    .ivu-form-item-content{
                        width: 2.1rem;
                        .ivu-input-group{
                            .ivu-input-group-prepend{
                                height: 0.3rem;
                                line-height: 0.3rem;
                            }
                        }
                        
                    }
                }
                .ivu-input-group{
                    position: relative;
                    display: block;
                    .ivu-input-group-prepend{
                        width: 1.2rem;
                        text-align: left;
                        border:none;
                        background: none;
                        color: #b80103;
                        font-size: 0.16rem;   
                        padding: 0;
                        text-indent: 0.04rem;
                        height: 0.3rem;
                        .ivu-select-selection{
                            >div{
                                position: relative;
                            }
                            .ivu-select-selected-value{
                                font-size:0.16rem;   
                                color: #b80103;
                                height: 0.3rem;
                                line-height: 0.3rem;
                                padding-left: 0;
                                padding-right: 0.24rem;
                            }
                        }
                        
                    }
                    .ivu-input{
                        border: none;
                        border-radius: 0;
                        border-bottom: 1px solid #b80103;
                        text-indent: 0.04rem;
                        height: 0.32rem;
                        line-height: 1.5;
                        padding: 0;
                        font-size: 0.14rem;
                    }
                }
                
            }
            .form-append-{
                 margin-right:0;
                .ivu-form-item-label{
                    width: 1rem;
                    line-height: unset;
                    padding: 0.4rem 0.12rem 0 0;
                    color: #333;
                }
                 .ivu-form-item-content{
                    margin-left: 1rem;
                    width: 2.5rem;
                }
                .ivu-input-group{
                    .ivu-input-group-append{
                        border: none;
                        line-height: 0.3rem;
                        background: none;
                        padding: 0;
                        padding-top: -0.02rem;
                        display: block;
                        margin-top: 0.04rem;
                        font-size: 0.18rem;
                        position: relative;
                        width: 1rem;
                        float: left;
                    }
                    .ivu-input{
                        border: none;
                        border-radius: 0;
                        padding:0;
                        width: 1rem;
                        border-bottom: 1px solid #bf6765;
                    }
                }
            }
            .form-append{
                margin-right:0;
                .ivu-form-item-label{
                    width: 1rem;
                    line-height: unset;
                    padding: 0.23rem 0.12rem 0 0;
                    color: #333;
                }
                .ivu-form-item-content{
                    margin-left: 1rem;
                    width: 2.4rem;
                    padding: 0.23rem 0.12rem 0 0;
                }

                .ivu-input-group{
                    .ivu-input-group-append{
                        border: none;
                        line-height: 0.4rem;
                        background: none;
                        padding: 0;
                        padding-top: -0.02rem;
                        display: block;
                        margin-top: -0.09rem;
                        font-size: 0.18rem;
                        position: relative;
                        width: 1rem;
                        float: left;
                    }
                    .ivu-input{
                        border: none;
                        border-radius: 0;
                        padding:0;
                        width: 1rem;
                        border-bottom: 1px solid #bf6765;
                    }
                }
                
            }
            .home-submit{
                height: 0.48rem;
                border-radius: 0.24rem;
                font-size: 0.2rem;
                background: #bc2628;;
                margin-top: 0.08rem;
            }
        }
    }
}
.calc-pop{
     .ivu-modal{
         width: 8rem!important;
     }
    .ivu-modal-content{
        border-radius: 0;
    }
    .ivu-modal-body{
        padding: 0;
        .pop-sure{
            float: right;
            padding: 0px 0.4rem;
            border-radius: 100px;
            font-weight: bolder;
            height: 40px;
            font-size: 18px;
            color: #bc2628;
            background: #bc262885;
            border: none;
        }
        .pop-container{
            display: flex;
            &>div{
                width: 3.4rem;
                &:first-child{
                    width: 4.6rem;;
                }
                &.left-pic{
                    img{
                        width: 100%;
                    }
                }
                .calc-res{
                    font-size:0.4rem;
                    color:red;
                    text-align:center;
                }
                &.main{
                    padding: 20px;
                    .main-title{
                        font-size: 0.2rem;
                    }
                    .conclusion{
                        margin: 0.2rem 0rem;
                        border-bottom: 1px solid #ccc;
                        ul{
                            li{
                                border: 1px solid #ccc;
                                border-bottom: none;
                                font-size: 0.2rem;
                                display: flex;
                                list-style: none;
                                div{
                                    font-weight: bolder;
                                    padding: 0.06rem 0.1rem;
                                    color: #000;
                                    text-align: center;
                                    width: 1.8rem;
                                }
                                div.title{
                                     font-weight: normal;
                                    border-right: 1px solid #ccc;
                                    width: 1rem;
                                    color: #999;
                                    text-align: left
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

</style>