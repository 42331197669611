<template>
    <div id="service-anchor">
        <Row class="service-content block">
            <div class="title">
                <h1>海外仓服务</h1>
                 <div class="dash"></div>
            </div>
           <div class="content">
                <Tabs type="card" :value="activeTab" @on-click="clickTab">
                    <TabPane label="订单中转分发" name="split">
                       <div class="tab-content">
                           <div class="tab-content-item-3">
                               <img :src="split1">
                               <p>支持海外仓换单</p>
                           </div>
                            <div class="tab-content-item-3">
                               <img :src="split2">
                               <p>订单异常拦截</p>
                           </div>
                            <div class="tab-content-item-3">
                               <img :src="split3">
                               <p>投递异常补发</p>
                           </div>
                       </div>
                    </TabPane>
                    <TabPane label="退换货处理" name="exchange">
                        <div class="tab-content">
                           <div class="tab-content-item-3">
                               <img :src="exchange1">
                               <p>退换货处理</p>
                           </div>
                            <div class="tab-content-item-3">
                               <img :src="exchange2">
                               <p>检查拍照</p>
                           </div>
                            <div class="tab-content-item-3">
                               <img :src="exchange3">
                               <p>3个月免费仓储</p>
                           </div>
                       </div>
                    </TabPane>
                    <TabPane label="贴标换标" name="labelling">
                        <div class="tab-content">
                           <div class="tab-content-item-2">
                               <img :src="labelling1">
                               <p>FBA换标</p>
                           </div>
                            <div class="tab-content-item-2">
                               <img :src="labelling2">
                               <p>转运FBA</p>
                           </div>
                       </div>
                    </TabPane>
                    <TabPane label="一件代发" name="helpsend">
                         <div class="tab-content">
                           <div class="tab-content-item-4">
                               <img :src="helpsend1">
                               <p>多仓同步发货</p>
                           </div>
                            <div class="tab-content-item-4">
                               <img :src="helpsend2">
                               <p>自研仓储系统</p>
                           </div>
                           <div class="tab-content-item-4">
                               <img :src="helpsend3">
                               <p>实时监控库存</p>
                           </div>
                           <div class="tab-content-item-4">
                               <img :src="helpsend4">
                               <p>精准费用账单</p>
                           </div>
                       </div>
                    </TabPane>
                    <TabPane label="关税账单处理" name="bill">
                        <div class="tab-content">
                           <div class="tab-content-item-2">
                               <img :src="bill1">
                               <p>头程清关辅助</p>
                           </div>
                            <div class="tab-content-item-2">
                               <img :src="bill2">
                               <p>关税账单代付</p>
                           </div>
                       </div>
                    </TabPane>
                    <TabPane label="英文客服" name="enservice">
                         <div class="tab-content">
                           <div class="tab-content-item-2">
                               <img :src="enservice1">
                               <p>美国英文客服</p>
                           </div>
                            <div class="tab-content-item-2">
                               <img :src="enservice2">
                               <p>可靠售后服务</p>
                           </div>
                       </div>
                    </TabPane>
                    
                </Tabs>
           </div>
        </Row>
    </div>
</template>
<script>
    import split1 from "@/assets/split1.png" 
    import split2 from "@/assets/split2.png" 
    import split3 from "@/assets/split3.png" 
    import exchange1 from "@/assets/exchange1.png" 
    import exchange2 from "@/assets/exchange2.png" 
    import exchange3 from "@/assets/exchange3.png" 
    import labelling1 from "@/assets/labelling1.png" 
    import labelling2 from "@/assets/labelling2.png"
    import helpsend1 from "@/assets/helpsend1.png"
    import helpsend2 from "@/assets/helpsend2.png"
    import helpsend3 from "@/assets/helpsend3.png"
    import helpsend4 from "@/assets/helpsend4.png"
    import bill1 from "@/assets/bill1.png"
    import bill2 from "@/assets/bill2.png"
    import enservice1 from "@/assets/enservice1.png"
    import enservice2 from "@/assets/enservice2.png"
    export default {
        data () {
            return {
                theme: 'primary',
                split1,
                split2,
                split3,
                exchange1,
                exchange2,
                exchange3,
                labelling1,
                labelling2,
                helpsend1,
                helpsend2,
                helpsend3,
                helpsend4,
                bill1,
                bill2,
                enservice1,
                enservice2,
                activeTab:'split'
            }
        },
        props: {
            activeSevice: String
        },
        watch:{
            activeSevice(val){
                if(val) this.activeTab = this.activeSevice
            }
        },
        mounted(){
            
        },
        methods:{
            clickTab(item){
                this.activeTab = item
            }
        }
    }
</script>
<style lang="less">
    .service{
        width: 12.8rem;
        margin: 0 auto;
        .content{
            width: 100%;

             .tab-content{
                display: flex;
                img{
                    width: 100%;
                }
                p{
                    font-size:0.28rem;
                    line-height: 2.4;
                    text-align: center;
                }
                &-item-4{
                    width: 25%;
                    padding: 0.12rem;
                }
                &-item-3{
                    width: 33.3333%;
                    padding: 0.12rem;
                }
                &-item-2{
                    width: 50%;
                    padding: 0.12rem;
                }
            }
            .ivu-tabs-bar{
                border-bottom:none;
                margin-bottom:0.4rem;
                .ivu-tabs-ink-bar{
                    display: none;
                }
                .ivu-tabs-nav{
                    width: 100%;
                    background: #dcdddd;
                    border-radius: 1rem;
                    text-align: center;
                }
                .ivu-tabs-nav-container{
                    height: unset;
                }
                .ivu-tabs-tab{
                    width: 16.6666666666666%;
                    font-size: 0.2rem;
                    line-height: 1.5;
                    color: #fff;
                    background: unset;
                    color: #666;
                    border:none;
                    margin-right: 0;
                    height: unset;
                }
                .ivu-tabs-tab-active{
                    border-radius: 1rem;
                    height: unset;
                    color: #fff;
                    font-size: 0.2rem;
                    background: #b5010d;
                }
                
            }
        }
    }
</style>
