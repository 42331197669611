<template>
  <div class="header">
    <Homecarousel></Homecarousel>
    <div class="fix-layer">
      <!-- <div class="switch-lang">
        <Menu mode="horizontal" theme="primary" active-name="1">
          <Submenu name="1">
              <template slot="title">
                  简体中文
              </template>
              <MenuItem name="1-1">简体中文</MenuItem>
              <MenuItem name="1-2">英文</MenuItem>
          </Submenu>
        </Menu>
      </div> -->
      <div class="banner">
        <div class="banner-left">
          <img :src="logo" />
        </div>
        <MainMenu @swithItem="swithItem" class="banner-menu"></MainMenu>
      </div>
      <div class="home-cas-text">
          <div class="home-cas-text-sub">专注中小件品类海外仓服务</div>
          <div class="home-cas-text-title">安全 <span class="casdot">·</span> 稳定 <span class="casdot">·</span> 高效 </div>
      </div>
      <HomeForm class="home-form-show"></HomeForm>
    </div>
    <div class="home-content why-choose">
      <WhyChoose></WhyChoose>
    </div>
    <div class="advantage">
      <Advantage></Advantage>
    </div>
    <div class="home-content solution">
      <Solution :activeSolution="activeSolution"></Solution>
    </div>
    <div class="home-content service">
      <Service :activeSevice="activeSevice"></Service>
    </div>
    <div class="home-content because">
      <Because></Because>
    </div>
    <div class=" winwin">
      <Winwin></Winwin>
    </div>
    <div class="flow">
      <Flow></Flow>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
<script>
import Homecarousel from "./components/homecarousel"
import MainMenu from "./components/menu"
import HomeForm from "./components/homeform"
import WhyChoose from "./components/whyChoose"
import Advantage from "./components/advantage"
import Solution from "./components/solution"
import Service from "./components/service"
import Because from "./components/because"
import Winwin from "./components/winwin"
import Flow from "./components/flow"
import Footer from "./components/footer"
import logo from "@/assets/logo.png" 
export default {
  name: 'home',
  components: {
    Homecarousel,
    MainMenu,
    WhyChoose,
    Advantage,
    Solution,
    Service,
    Because,
    Winwin,
    Flow,
    Footer,
    HomeForm
  },
  data() {
    return {
      logo,
      activeSevice:'split',
      activeSolution : '0'
    };
  },
  props: {
    msg: String
  },
  methods:{
    swithItem(type,menuName){
      if(type==='service'){
        this.activeSevice = menuName
        console.log('this.activeSevice',this.activeSevice)
      }if(type==='solution'){
        this.activeSolution = menuName
        console.log('this.activeSolution',this.activeSolution)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.because-item .content-item.ivu-col-offset-2{
      margin-left: 10%;
    flex: 0 0 40%;
    max-width: 40%;
  }
.block{
  .title{
    width: 100%;
    margin: 1rem 0 0.4rem 0;
    text-align: center;
    h1{
        font-size: 0.5rem;
        color: #000;
        font-weight: bolder;
        line-height: 1.5;
    }
    .dash{
        width: 0.28rem;
        height: 0.06rem;
        margin: 0.3rem auto;
        background:#b80103
    }
  }
}
.header{
  .fix-layer{
    position: absolute;
    top:0;
    width: 100%;
    .switch-lang{
      display: flex;
      position: absolute;
      width: 100%;
      padding-right: 0.6rem;
      justify-content: flex-end;
      .ivu-menu-horizontal{
        height: 0.6rem;
        line-height: 0.6rem;
      }
      .ivu-menu-primary{
        background: unset;
      }
      z-index: 1000;
    }
    .banner{
      margin: 0.2rem 0.6rem 0.1rem 0.6rem;
      // margin: 0.6rem 0.6rem 0.1rem 0.6rem;
      display: flex;
      justify-content: space-between;
      .banner-left{
        width: 2.8rem;
        position: relative;
        img{
          width: 100%;
          top: 50%;
          transform: translate(0,-50%);
          position: absolute;
        }
      }
    }
    .home-cas-text{
        position: relative;
        width: 12.6rem;
        margin: 1.4rem auto 0 auto;  
        &-sub{
            font-size: 0.5rem;
            line-height: 0.8rem;
            color: #fff;
        }
        &-title{
            font-size: 0.8rem;
            line-height: 1.2rem;
            font-weight: bolder;
            color: #fff;
        }
    }
    .home-form-show{
      position: relative;
      width: 12rem;
      margin: 0.3rem auto 0 auto;
      // position: absolute;
      // left: 3rem;
    }
  }
  
}
//mediaquery 兼容 1000以下
// 待完善
 @media screen and (min-width: 1001px) and (max-width: 1350px){
   .header .fix-layer .home-form-show{
      width: 12.4rem;
    }
 } 
 @media screen and (min-width: 800px) and (max-width: 1000px){
   .header .fix-layer .home-cas-text{
    margin-top: 0.4rem;
   }
   .header .fix-layer .home-form-show{
      width: 12.4rem;
    }
 }
 
  @media screen and (min-width: 600px) and (max-width: 799px){
    .header .fix-layer .home-form-show{
      width: 10.6rem;
    }
    .home-carousel{
      height: 480px;
      .ivu-carousel{
        height: 100%;
        .cas-img{
          height: 480px;
          width: unset;
        }
      }
    }
    .header .fix-layer .banner .banner-left{
      width: 100px;
    }
   .header .fix-layer .banner{
      margin: 10px;
      .banner-menu{
        padding: 0 8px 0 0;
      }
      .ivu-menu-horizontal .ivu-menu-item, .ivu-menu-horizontal .ivu-menu-submenu{
        padding: 0 4px
      }
      .ivu-menu-drop-list .ivu-menu-item{
        padding: 7px 16px;

      }
   }

   .home-content{
     width: 100%;
     padding: 0 10px ;
   }
   .header .fix-layer .home-cas-text{
    max-width: 100%;
   }
   .header .fix-layer .home-form-show{
      max-width: 100%;
      margin: 0.1rem auto 0 auto;

   }
   .home-form .home-form-container .ivu-form .ivu-form-item-label{
     width:30px;
     padding: 0;
   }
   .home-form .home-form-container .ivu-form .form-append- .ivu-form-item-label{
      width:40px;
   }
   .home-form .home-form-container .ivu-form .ivu-form-item-content{
     width: 80px;
     margin-left: 40px;
   }
   .home-form .home-form-container .ivu-form .form-append- .ivu-form-item-content{
     margin-left: 40px;
     width: 120px;
   }
   .home-form .home-form-container .ivu-form .form-append- .ivu-input-group .ivu-input-group-append{
     width: 60px;
   }
   .advantage .content-text-item .ivu-tabs-bar{
     margin-bottom: 10px;
     
   }
   .advantage .content-text-item .advantage-tab-img img{
     height: 24px;
   }
   .advantage .content-text-item .advantage-tab-img .advantage-tab-{
     margin: 10px 0;
   }
   .advantage .content-text-item .advantage-tab-text{
     font-size: 14px;
   }
   .advantage .content-text-item .ivu-tabs-bar .ivu-tabs-tab{
     margin-right:10px;
     padding: 8px 12px
   }
   .winwin-item p {
        width: 600px;
        margin: 0 auto;
        font-size: 0.78rem;
        color: #fff;
        position: relative;
        display: block;
        top: 32px;
        text-indent: 4px;
    }
    .footer-item-content,
    .flow-item{
       width: 100%;
    }
 }
 @media screen and (max-width: 599px){
   .block .title h1{
     font-size: 14px;
   }
   .header .fix-layer {
     .home-cas-text-sub{
      font-size: 16px;
      line-height: 32px;
    }
    .home-cas-text-title{
      font-size: 32px;
      line-height: 64px;
    }
   }
    .home-carousel{
      height: 470px;
      .ivu-carousel{
        height: 100%;
        .cas-img{
          height: 460px;
          width: unset;
        }
      }
    }
    .header .fix-layer .banner .banner-left{
      width: 48px;
    }
   .header .fix-layer .banner{
      margin: 10px;
      .banner-menu{
        padding: 0 0px 0 8px;
      }
      .ivu-menu-horizontal{
        font-size: 12px;
      }
      .ivu-menu-submenu-title span>i, .ivu-menu-submenu-title>i{
        margin-right:2px
      }
      .ivu-menu-horizontal .ivu-menu-item, .ivu-menu-horizontal .ivu-menu-submenu{
        padding: 0;
        font-size: 12px;
      }
      .ivu-menu-drop-list .ivu-menu-item{
        padding: 7px 16px;
      }
      .ivu-menu-item .ivu-btn{
        font-size: 12px;
        margin-left: 0px;
        padding:0 10px;
        margin-left: 5px;
      }
   }

   .home-content{
     width: 100%;
     padding: 0 10px ;
   }
   .header .fix-layer .home-cas-text{
    max-width: 100%;
    padding: 0 10px ;
    margin: 20px 10px 10px 10px;
   }
   .header .fix-layer .home-form-show{
      width: calc(~"100% - 20px");
      margin: 10px;

   }
   .solution-item .content-item {
    .item-base-text{
      font-size:12px;
      line-height: 26px;
    height: 57px;
    }
    .item-mask-contaniner {
      img{
        width: 24px;
        margin: 10px;
      }
      div{
        font-size: 12px;
        line-height: 20px;
      }
    }
   }
   .service .content .ivu-tabs-bar .ivu-tabs-tab{
     white-space: normal;
     text-align: center;
     font-size: 12px;
     padding: 5px 6px 4px;
     width: unset;
   }
    .because-item .content-item.ivu-col-offset-2{
      margin-left: 1%;
    flex: 0 0 48.66666667%;
    max-width: 48.66666667%;
  }
   .because-item .content-item{
      .icon-circle{
        width:32px;
        height: 32px;
        img{
          width: 16px;
        }
      }
      div{
        font-size: 12px;
        line-height: 32px;
      }
   } 
   .footer-copyright p{
     font-size: 12px;
   }
   .home-form .home-form-container .ivu-form .ivu-form-item{
     margin-bottom: 20px;
   }
     .advantage .ivu-tabs-nav-container{
       margin-top: 20px;

   }
   .advantage .content-text-item .ivu-tabs-bar{
     margin-bottom: 10px;
     
   }
   .advantage .content-text-item .advantage-tab-img img{
     height: 16px;
   }
   .advantage .content-text-item .advantage-tab-img .advantage-tab-{
     margin: 6px 0;
     width: 16px;
   }
   .advantage .content-text-item .advantage-tab-text{
     font-size: 14px;
   }
    .advantage .ivu-tabs-nav-scrollable{
     padding: 0;
   }
   .advantage .content-img{
     height: 160px;
   }
   .advantage .content-text-item .ivu-tabs-bar{
      .ivu-tabs-tab{
        margin-right:10px;
        padding: 4px 6px
      }
   }
   .winwin-item p {
        width: 100%;
        margin: 0 auto;
        font-size: 0.78rem;
        color: #fff;
        position: relative;
        display: block;
        top: 32px;
        text-indent: 4px;
    }
    .footer-item-content,
    .flow-item{
       width: 100%;
    }
    .footer-item-content{
      .ivu-col-span-3{
        max-width: 19%;
        flex: 0 0 19%;
      }
      .ivu-col-offset-6{
        margin-left: 10%;
      }
      .ivu-col-span-4{
        margin-left: 10px;
      }
    }
 }
</style>
